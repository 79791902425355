import { TrashIcon } from '@heroicons/react/outline';
import { PaperClipIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { humanFileSize } from './getHumanReadableFileSize';
import * as styles from './styles';

interface AcceptedFileProps {
  file: File;
  onFileDelete: () => void;
  disabled?: boolean;
}

const AcceptedFile = ({
  file,
  onFileDelete,
  disabled = false,
}: AcceptedFileProps) => {
  return (
    <div
      className={classNames(styles.acceptedFile, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.content}>
        <PaperClipIcon className={styles.fileIcon} />
        <div className={styles.fileText}>
          <h1
            className={classNames(styles.fileName, {
              [styles.fileNameDisabled]: disabled,
            })}
          >
            {file.name}
          </h1>
          <p className={styles.fileSize}>{humanFileSize(file.size)}</p>
        </div>
      </div>
      <button onClick={onFileDelete} disabled={disabled}>
        <TrashIcon
          className={classNames(styles.deleteFileIcon, {
            [styles.deleteFileDisabled]: disabled,
          })}
        />
      </button>
    </div>
  );
};

export default AcceptedFile;
