import classNames from 'classnames';

export const acceptedFile = classNames(
  'animate-appear-down',
  'flex',
  'items-center',
  'justify-between',
  'border',
  'border-indigo-500',
  'rounded-[6px]',
  'px-[20px]',
  'py-[16px]',
  'bg-white'
);

export const disabled = classNames('!bg-indigo-50', 'border-none');

export const content = classNames('flex', 'items-center');

export const fileIcon = classNames('w-[20px]', 'text-gray-400', 'mr-[11px]');

export const fileText = classNames('flex', 'flex-col');

export const fileName = classNames('text-sm', 'text-gray-900');
export const fileNameDisabled = classNames('!text-gray-600');

export const fileSize = classNames('text-sm', 'text-gray-500');

export const deleteFileIcon = classNames('w-[20px]', 'text-indigo-300');

export const deleteFileDisabled = classNames('opacity-50', 'cursor-default');
